import React, {Component} from "react";
import "./css/CoverPage.css";

class CoverPage extends Component {

    render() {
        return (
            <div className="cover-page">
                <div className="cover-card">
                    <div className="text-container">
                        <h1>James Packard</h1>
                        <span>Computer Science / Computer Engineering</span>
                    </div>
                </div>
                <div className="scroll-container">
                    <span>[ scroll for more ]</span>
                </div>
            </div>
        )
    }

}

export default CoverPage;
