import React, {Component} from "react";
import CoverPage from "./CoverPage";
import "./css/App.css";
import Project from "./Project";


const projects = [
  {
    title: "iotLEDs",
    date: "October 2019",
    video: "/img/iotleds",
    github: "https://github.com/packard-j/iotleds",
    text: `
      This semester my roommates and I put up three strips of addressable LEDs in our apartment.
      I programmed a web server on a Raspberry Pi to control the lights over the
      internet, allowing students passing by to change their color or make their own pattern.`
  },
  {
    title: "PassDB",
    date: "January 2019",
    img: "/img/passdb.png",
    link: "https://passdb.jamespackard.me/about",
    text: `
      PassDB is an open source password manager built using modern web technologies. It encrypts passwords
      before they leave the browser using the WebCrypto API, preventing attackers from intercepting them
      even if HTTPS fails. New devices are registered using a form of 2FA that requires both the user's EMK
      (Encrypted Master Key) be sent through a WebSocket from a trusted device and their master password
      be typed in to authenticate.`
  },
  {title: "Encrypted Chat",
    date: "March 2018",
    img: "/img/securechat_screenshot.png",
    link: "https://0x00sec.org/t/encrypted-chat-part-i/5839",
    text: `
      The Encrypted Chat series is a three article project walk-through I created to demonstrate the
      use of symmetric encryption and Diffie-Hellman key exchange. It explains step-by-step how I created
      an encrypted client-server chat room in pure Python using these concepts.`
  },
  {
    title: "PieRSquared",
    date: "September 2017",
    img: "/img/prs.png",
    link: "https://www.piersquared.org/",
    linkText: "PieRSquared Site",
    noShadow: true,
    text: `During my last year of high school I tutored math at PieRSquared in Roxbury, MA. While there, I developed
    an application to replace their paper registration and attendance system, allowing students to register online
    an sign in on a computer when they arrive. This system in turn allows the administrators to view metrics such as
    frequency of attendance, distribution of students by school, grade, or math level, and the positive correlation
    between attendance and improvement in math.`
  },
  {title: "VC Graph",
    date: "September 2017",
    img: "/img/vcgraph.png",
    text: `
    VC Graph was a tool I created for my high school that allowed students and academic advisers
    to view grades across all classes in a single graph. It loaded data from the school's grading platform,
    Veracross, and calculated the student's grades for each day in the academic quarter to produce
    the graph. It also aggregated missing assignments, calculated GPA, and indicated what grades must be
    achieved on upcoming assignments to reach a desired grade in each class.
    `
  },
  {title:"U.S. Tides",
    date:"June 2016",
    img:"/img/tides.jpg",
    link: "http://ustides.org",
    linkText: "View Site",
    text: `
      U.S. Tides is a website I created in 2016 as one of my first ventures into web programming.
      Using tide and weather data from the NOAA, U.S. Tides shows a 24-hour graph of the water level
      at the desired location and the water temperature reported from the nearest station.`}
];

class App extends Component {


    render() {

        return (
            <div>
                <CoverPage/>
                    <div className="projects-container">
                        <div className="about">
                            <div className="profile-photo">
                            <img src="/img/profile.jpg" alt="Profile"/>
                            </div>
                            <h1>I'm James, an undergraduate student studying computer science and computer engineering at
                            Northeastern University.</h1>
                            <p>My interests include modern web, computer security, and internet connected hardware.</p>
                        </div>
                        <div className="projects-header">
                          <h1>Notable Projects</h1>
                          <p>A compilation of my favorite projects made in my free time.</p>
                          <div className="btn-group">
                            {projects.map((p, idx) => <button key={idx} className="btn" onClick={
                                () => {
                                  const rect = document.getElementById(`project-${idx}`).getBoundingClientRect();
                                  window.scrollTo(0, rect.top + window.scrollY);
                                }
                              }>
                              {p.title}
                            </button>)}
                          </div>
                        </div>
                        {projects.map((p, idx) => <Project key={idx} id={`project-${idx}`} {...p}/>)}
                    </div>
            </div>
        )
    }

}

export default App;
